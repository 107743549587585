/*
    DEFAULT LIMITS = isDefaultLimit: true && isUserLimit: false
    CUSTOM LIMITS = isDefaultLimit: false && isUserLimit: false
    USER/PERSONAL LIMITS = isDefaultLimit: false && isUserLimit: true
*/
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { omit, some, uniq } from 'lodash';
import { Link, useHistory } from 'react-router-dom';

import { Expandable, GeneralButton, GeneralDialog, GeneralSelect, LoadingStatusWrapper, SearchBox } from 'sharedComponents';
import { translate } from 'helpers/translateHelper';
import { IconCaretRight, IconDropDownMo, IconLeftArrow, IconPlus } from 'svgIcons/MotionPortalIcons';
import LimitTitle from './components/LimitTitle';
import { addSignal, limitTypeChange, resetState, cancel, postLimitRequest } from './actions';
import { LimitTypeOptions, SLIDER_VALUES, RANGE, WINDOW_EVENTS } from './components/constants';
import Limit from './components/Limit';
import { MultiPointSlider } from 'sharedComponents/Slider';
import { getSliderValues } from './components/helper';
import Option from './components/Option';
import SuggestLimitModal from './components/SuggestLimitModal';
import { routes } from 'routes';
import NotificationBanner from './components/NotificationBanner';
import PersonalLimit from './components/PersonalLimit';
import { isSmSeAsset } from '../helpers';
import colors from 'theme/_colors.scss';
import { apiCallStatus } from 'helpers/constants';

const getPayload = (timeSeries) => {
    const filteredTimeSeriesWithModifiedLimits = timeSeries?.filter(item => item.isModified).map(item => ({
        ...omit(item, ['isModified', 'uniqId', 'isNew', 'unit', 'displayName', 'displayNameTextKey', 'appendCodeToTranslation', 'isOffsetEnabled', 'isDefaultLimitEditable', 'isAddingCustomLimitsAllowed', 'isHealthTimeseries', 'minValue', 'maxValue']),
        limits: item.limits?.map(limit => ({
            resetToDefault: false,
            ...omit(limit, ['uniqId', 'isError', 'isModified', 'isSameLimit', 'isDefault', 'limitType', 'referenceTimeseriesKey', 'isBasedOnDefault']),
        })
        )
    }));

    return filteredTimeSeriesWithModifiedLimits;
};

const adjustMinMax = (data, type) => {
    if (data.length === 0) {
        return null;
    }

    const values = data.map(item => Number(item.value));

    let minVal = Math.min(...values);
    let maxVal = Math.max(...values);

    // Custom logic to adjust min and max values
    if (minVal === maxVal) {
        if (type === RANGE.Min) {
            minVal -= 1; // Ensuring minimum is adjusted down
        }
        if (type === RANGE.Max) {
            maxVal += 1; // Ensuring maximum is adjusted up
        }
    }

    if (type === RANGE.Min) {
        let adjustedMin = minVal <= 0 ? Math.ceil(minVal * SLIDER_VALUES.MAX) : Math.floor(minVal * SLIDER_VALUES.MIN);
        if (minVal === 1 || minVal === 0) {
            adjustedMin = -1;
        }
        return adjustedMin;
    } else if (type === RANGE.Max) {
        const adjustedMax = maxVal < 0 ? Math.ceil(maxVal * SLIDER_VALUES.MIN) : Math.floor(maxVal * SLIDER_VALUES.MAX);
        return adjustedMax + 1;
    }
};

const getMinValue = (timeSeries, type) => {
    const minValue = timeSeries?.minValue;
    const maxValue = timeSeries?.maxValue;
    const defaultLimits = timeSeries?.limits?.filter((limit) => !limit?.isUserLimit); // ALSO INCLUDE CUSTOM LIMITS
    if (type === RANGE.Min) {
        return minValue !== undefined && minValue !== null ? minValue : adjustMinMax(getSliderValues(defaultLimits), RANGE.Min);
    } else if (type === RANGE.Max) {
        return maxValue !== undefined && maxValue !== null ? maxValue : adjustMinMax(getSliderValues(defaultLimits), RANGE.Max);
    }
};

const getSearchMatcher = (search) => {
    const searchToLower = search?.toString()?.toLowerCase();
    return (value) => value?.toString()?.toLowerCase()?.includes(searchToLower);
};

const LimitConfigComponent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { model, asset, actions } = props;
    const [isDisableSave, setIsDisableSave] = useState(false);
    const [isDisableCancel, setIsDisableCancel] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showNotifyMeMessage, setShowNotifyMeMessage] = useState(true);
    const [showMaxLimitMessage, setShowMaxLimitMessage] = useState(true);
    // const [nextLocation, setNextLocation] = useState(null);
    const [contextMenuId, setContextMenuId] = useState(null);
    const [expandedItem, setExpandedItem] = useState(model?.expandedSignals || []);
    const [expandedItemFromChart, setExpandedItemFromChart] = useState(false);
    const unblockRef = useRef(null);
    const checkIsSmseAsset = isSmSeAsset(asset);
    const timeseriesLoading = model?.timeSeries?.isLoading === apiCallStatus.LOADING;
    const hasModifiedItem = model.currentTimeSeries?.some(item =>
        item?.isModified || item?.limits?.some(limit => limit?.isModified)
    );

    useEffect(() => {
        setExpandedItem(uniq(model?.expandedSignals) || []);
    }, [model?.expandedSignals]);

    useEffect(() => {
        if (hasModifiedItem) {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = '';
            };

            unblockRef.current = history.block((location) => {
                if (location.pathname !== routes.OperationalParametersV2 && hasModifiedItem) {
                    // setNextLocation(location);
                    setShowModal(true);
                    return false;
                }
                return true;
            });

            window.addEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, handleBeforeUnload);

            return () => {
                window.removeEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, handleBeforeUnload);
                if (unblockRef.current) { unblockRef.current(); }
            };
        }
    }, [hasModifiedItem, history]);

    useEffect(() => {
        if (timeseriesLoading) {
            setIsDisableSave(true);
            setIsDisableCancel(true);
        } else {
            setIsDisableCancel(false);
            const hasModifiedItemWithoutDisplayName = model?.currentTimeSeries?.some(item =>
                item?.isModified && (!item?.displayName || item?.displayName?.length === 0)
            );

            const hasInvalidLimits = model.currentTimeSeries.filter(item => item.isModified).some(item =>
                some(item.limits, limit =>
                    !limit.limitLevel || limit.limitValue === undefined || limit.limitValue === null || limit.limitValue === '' || !limit.limitDirection || limit.isSameLimit
                )
            );

            const hasNoLimits = model?.currentTimeSeries?.filter(item => item?.limits?.length === 0);

            const hasModifiedSignals = model?.currentTimeSeries?.filter(item => item?.isModified);
            if (hasModifiedSignals?.length) {
                if (hasModifiedItemWithoutDisplayName || hasInvalidLimits || hasNoLimits?.length > 0) {
                    setIsDisableSave(true);
                } else {
                    setIsDisableSave(false);
                }
            } else {
                setIsDisableCancel(true);
                setIsDisableSave(true);
            }
        }
        const hasNewLimits = model?.currentTimeSeries?.filter(item => item?.isNew);
        if (hasNewLimits?.length) {
            setIsDisableCancel(false);
        }
    }, [model.currentTimeSeries]);

    useEffect(() => {
        // COMMENTED FOR UPDATE UNIT CONVERSION
        // if (asset?.id === model?.timeSeries?.assetId) {
        //     return;
        // }

        dispatch(resetState());
        actions.getLimitKPI(asset?.id);
        actions.getTimeSeries(asset?.id);
    }, [asset?.id]);

    const handleAddSignal = () => {
        const newSignal = {
            uniqId: crypto.randomUUID(),
            limits: [],
            isModified: true,
            isNew: true,
        };
        dispatch(addSignal(newSignal));
    };

    const handleLimitTypeChange = (value, item) => {
        dispatch(limitTypeChange(item, value));
    };

    const handleCancel = () => {
        dispatch(cancel());
        const newSignal = model?.currentTimeSeries?.find((cts) => cts?.isNew);
        if (newSignal) {
            actions.setExpandedSignals(expandedItem.filter((id) => newSignal?.displayNameTextKey !== id && `P_${newSignal?.displayNameTextKey}` !== id));
        }
    };

    const handleSaveConfig = () => {
        const payload = getPayload(model?.currentTimeSeries, model?.limitKPI?.kpiList);
        dispatch(postLimitRequest(asset?.id, payload));
    };

    const filteredList = useMemo(() => {
        const isSearchMatch = getSearchMatcher(searchValue);

        const timeseries = model?.currentTimeSeries ?? [];

        return searchValue
            ? timeseries?.filter((item) =>
                isSearchMatch(item?.displayName) ||
                isSearchMatch(item?.timeseriesKey) ||
                isSearchMatch(item?.trendKey)
            )
            : timeseries;
    }, [searchValue, model.currentTimeSeries]);

    const displayNameTextKeys = new Set(model?.currentTimeSeries?.map(item => item?.displayNameTextKey));

    const updatedConfigList = model?.limitKPI?.kpiList?.flatMap(configItem =>
        configItem?.timeseries?.filter(series => !displayNameTextKeys?.has(series?.timeseriesNameTextKey))
    );

    const hasEditableLimit = updatedConfigList?.length > 0;

    const handleSuggestLimitModal = (signal) => {
        actions.handleSelectTimeSeriesForSuggestion(!model.showSuggestLimitModal ? signal : null);
        actions.handleShowSuggestLimitModal(!model.showSuggestLimitModal);
        if (signal) {
            actions.getSelectedTimeSeriesSuggestedData({ assetId: asset.id, timeseriesKey: signal?.timeseriesKey });
        }
    };

    const handleDiscardAndContinue = () => {
        setShowModal(false);
        handleCancel();
        // if (nextLocation) {
        if (unblockRef.current) { unblockRef.current(); }
        // history.push(`${nextLocation?.pathname}${nextLocation?.search}`);
        actions.setShowLimitSetting(false);
        // }
    };

    const handleExpandSignal = (signalUniqId) => {
        let expandedSignals = [...expandedItem];
        if (expandedSignals?.includes(signalUniqId)) {
            expandedSignals = expandedItem.filter((id) => signalUniqId !== id && `P_${signalUniqId}` !== id);
        } else {
            expandedSignals?.push(signalUniqId);
            expandedSignals?.push(`P_${signalUniqId}`);
        }
        actions.setExpandedSignals(uniq(expandedSignals));
    };

    const handlePreSelectedLimits = () => {
        if (model?.selectedTrendKPIs?.length && model.currentTimeSeries?.length || model?.clickedKpiConfig) {
            const selectedSignal = model.currentTimeSeries?.filter((fl) => fl?.isTrend ? model?.selectedTrendKPIs?.includes(fl?.trendKey) || model?.clickedKpiConfig === fl?.trendKey : model?.selectedTrendKPIs?.includes(fl?.timeseriesKey) || model?.clickedKpiConfig === fl?.timeseriesKey);
            if (selectedSignal?.length && !timeseriesLoading) {
                selectedSignal?.map(ss => {
                    const signal = model.currentTimeSeries?.find((fl) => checkIsSmseAsset ? ss?.timeseriesKey === fl?.timeseriesKey : ss?.timeseriesKey === fl?.timeseriesKey && ss?.trendKey === fl?.trendKey);
                    if (signal) {
                        const expandItemName = signal?.displayNameTextKey;
                        setExpandedItem((oldState) => [...oldState, ...expandedItem, expandItemName, `P_${expandItemName}`]);
                    }
                });
                // SMOOTH SCROLL AFTER UI LOAD
                const signal = selectedSignal[0];
                const section = document.getElementById(`${signal?.timeseriesKey}`);
                section?.scrollIntoView({ behavior: 'smooth' });
                setExpandedItemFromChart(true);
            }
        }
    };

    useEffect(() => {
        if (!expandedItemFromChart) {
            handlePreSelectedLimits();
        }
    }, [model?.selectedTrendKPIs?.length, model.timeSeries?.isLoading, model?.clickedKpiConfig]);

    return (
        <div className='limit-config-container'>
            {showModal &&
                <GeneralDialog
                    show={showModal}
                    close={() => setShowModal(false)}
                    notificationType='warning'
                    title={translate('ABB.Powertrain.Frontend.unsavedChangesWarningPopupTitle')}
                    cancelButtonProps={{
                        text: translate('ABB.Powertrain.Frontend.limitDiscardButtonTitle'),
                        onClick: handleDiscardAndContinue
                    }}
                    acceptButtonProps={{
                        text: translate('ABB.Powertrain.Frontend.limitButtonGoBack'),
                        onClick: () => setShowModal(false)
                    }}
                    closeButton={true}
                    persistent={false}
                >
                    <div>
                        {translate('ABB.Powertrain.Frontend.unsavedLimitsPromtMessage')}
                    </div>
                </GeneralDialog>
            }
            <div className='limit-config-header'>
                <div className='header-left'>
                    <GeneralButton
                        type='discreet'
                        className='limit-btn limit-back-btn ps-0'
                        icon={<IconLeftArrow color={colors.black} />}
                        text={translate('ABB.Powertrain.Frontend.backButton')}
                        onClick={() => {
                            if (hasModifiedItem) { setShowModal(true); return; }
                            actions.setShowLimitSetting(false);
                            actions.setClickedConfigKpi(null);
                            actions.setExpandedSignals([]);
                            setExpandedItemFromChart(false);
                        }}
                    />
                    <SearchBox
                        className='limit-search-box'
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                        placeholder={translate('ABB.Powertrain.Frontend.searchBySignalName')}
                    />
                    {filteredList && filteredList?.length > 0 &&
                        <div className='limit-count'>
                            {filteredList?.length} {filteredList?.length > 1 ? translate('ABB.Powertrain.Frontend.signals') : translate('ABB.Powertrain.Frontend.signal')}
                        </div>
                    }
                    {/* Needed for future use case */}
                    {/* <GeneralButton
                        type='normal'
                        text={translate('ABB.Powertrain.Frontend.expandAll')}
                        className='limit-btn'
                        onClick={() => setIsExpanded(!isExpanded)}
                        disabled={model.timeSeries?.isLoading}
                    /> */}
                </div>
                <div className='header-right'>
                    <>
                        <GeneralButton
                            type='normal'
                            className='limit-config-Cancel-button'
                            text={translate('ABB.Powertrain.Frontend.generalFilterCancelButton')}
                            onClick={handleCancel}
                            disabled={isDisableCancel || timeseriesLoading}
                        />
                        <GeneralButton
                            disabled={isDisableSave || timeseriesLoading}
                            style={{ backgroundColor: '#9CBDF7 !important' }}
                            type='primary'
                            className='limit-config-save-button'
                            text={translate('ABB.Powertrain.Frontend.saveButton')}
                            onClick={handleSaveConfig}
                        />
                        <GeneralButton
                            type='primary'
                            text={translate('ABB.Powertrain.Frontend.addSignal')}
                            icon={<IconPlus color={'#ffffff'} />}
                            className='limit-btn'
                            onClick={handleAddSignal}
                            disabled={timeseriesLoading || !hasEditableLimit}
                        />
                    </>
                </div>
                {showNotifyMeMessage && <NotificationBanner
                    message={<div>
                        {translate('ABB.Powertrain.Frontend.limitsNotifyMeMessage')} {' '}
                        <Link
                            to={{ pathname: routes.NotificationSettings }}
                        >
                            {translate('ABB.Powertrain.Frontend.userProfileUserSettingsLabel')} - {translate('ABB.Powertrain.Frontend.userProfileNotificationSettings')}
                        </Link>
                    </div>}
                    onClose={() => { setShowNotifyMeMessage(false); }}
                    type='notification'
                />}
            </div>
            <LoadingStatusWrapper loadingStatus={[model?.timeSeries?.isLoading]} >
                <div className='signal-limit-container'>
                    {(filteredList ?? []).map(timeSeries => {
                        const defaultLimits = timeSeries?.limits?.filter((limit) => !limit?.isUserLimit); // ALSO INCLUDE CUSTOM LIMITS
                        const userLimits = timeSeries?.limits?.filter((limit) => limit?.isUserLimit); // ONLY USER LIMITS (PERSONAL LIMITS)
                        const notificationCount = defaultLimits?.filter((limit) => limit?.notificationEnabled)?.length;
                        const anyLimitEventActive = timeSeries?.limits?.find((limit) => limit?.eventEnabled);
                        const expandItemName = timeSeries?.displayNameTextKey;
                        const expanded = expandedItem?.includes(expandItemName);
                        return <Expandable
                            disabled={!timeSeries?.displayName}
                            open={!timeSeries?.displayName ? false : isExpanded || expanded}
                            key={expandItemName}
                            id={timeSeries?.timeseriesKey}
                            headerClassName={expanded ? 'expanded' : ''}
                            onClick={() => { handleExpandSignal(expandItemName); }}
                            collapsedIcon={<IconCaretRight />}
                            expandedIcon={<IconDropDownMo />}
                            title={
                                <LimitTitle
                                    {...props}
                                    item={timeSeries}
                                    setExpandedItem={actions.setExpandedSignals}
                                    expandedItem={expandedItem}
                                    handleExpandSignal={handleExpandSignal}
                                    isExpanded={isExpanded}
                                    setIsExpanded={setIsExpanded}
                                    openSuggestLimitModal={() => handleSuggestLimitModal(timeSeries)}
                                    isUserLimit={false}
                                    showDiscard={defaultLimits?.filter((l) => l?.isModified)?.length > 0}
                                    anyLimitEventActive={anyLimitEventActive}
                                    notificationCount={notificationCount}
                                    setShowMaxLimitMessage={setShowMaxLimitMessage}
                                    defaultLimits={defaultLimits}
                                    checkIsSmseAsset={checkIsSmseAsset}
                                    hasModifiedItem={hasModifiedItem}
                                    model={model}
                                    contextMenuId={contextMenuId}
                                    setContextMenuId={setContextMenuId}
                                    timeseriesLoading={timeseriesLoading}
                                    expandItemName={expandItemName}
                                    defaultLimitValues={model?.defaultLimits?.timeseries}
                                    defaultLimtsLoading={model?.defaultLimtsLoading}
                                />
                            }
                        >
                            <div className='content-container' id={timeSeries?.uniqId}>
                                {defaultLimits?.length === 0 &&
                                    <div className='limit-type-container'>
                                        <div className='limit-type'>
                                            <GeneralSelect
                                                options={LimitTypeOptions}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.value}
                                                components={{ Option }}
                                                onChange={(e) => {
                                                    handleLimitTypeChange(e.value, timeSeries);
                                                }}
                                                placeholder={translate('ABB.Powertrain.Frontend.limitTypePlaceholder')}
                                            />
                                        </div>
                                        <div className='no-limits'>
                                            {translate('ABB.Powertrain.Frontend.noLimitsMessage')}
                                        </div>
                                    </div>
                                }
                                {defaultLimits?.length >= 4 && showMaxLimitMessage && <div className='mx-4 my-3'> <NotificationBanner
                                    message={translate('ABB.Powertrain.Frontend.personalLimitsMessage')}
                                    onClose={() => { setShowMaxLimitMessage(false); }}
                                /></div>}
                                {defaultLimits?.length > 0 &&
                                    defaultLimits?.map(limit =>
                                        <Limit key={limit?.uniqId} item={limit} timeSeries={timeSeries} kpiList={model?.limitKPI?.kpiList} asset={asset} checkIsSmseAsset={checkIsSmseAsset} />
                                    )
                                }
                                {defaultLimits?.length > 0 &&
                                    <div className='limit-slider-container'>
                                        <MultiPointSlider
                                            uniqId={timeSeries?.uniqId}
                                            showPointer
                                            showMinMax={timeSeries?.minValue !== null}
                                            min={getMinValue(timeSeries, 'min')}
                                            max={getMinValue(timeSeries, 'max')}
                                            value={getSliderValues(defaultLimits)}
                                        />
                                    </div>
                                }
                            </div>
                            <PersonalLimit
                                {...props}
                                timeSeries={timeSeries}
                                isExpanded={isExpanded}
                                expandedItem={expandedItem}
                                setExpandedItem={actions.setExpandedSignals}
                                setIsExpanded={setIsExpanded}
                                userLimits={userLimits}
                                checkIsSmseAsset={checkIsSmseAsset}
                                handleExpandSignal={handleExpandSignal}
                                expandItemName={expandItemName} />
                        </Expandable>;
                    })}
                </div>
            </LoadingStatusWrapper>
            {model.showSuggestLimitModal &&
                <SuggestLimitModal {...props} close={handleSuggestLimitModal} />
            }
        </div>
    );
};

export default LimitConfigComponent;
