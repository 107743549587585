import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import slice from 'lodash/slice';
import { formatEventLogs, getColumnConfig } from './helpers';
import EventCategory from './EventCategory';
import { ITEMS_PER_PAGE } from './constants';
import './style.scss';
import { GeneralPagination, LoadingStatusWrapper } from 'sharedComponents';
import { sortingDirections, GeneralTable } from 'sharedComponents/GeneralTable';
import CloseEventDialogComponent from './CloseEventDialogComponent';
import { useNewCapability } from 'sharedHooks';
import { apiCallStatus } from 'helpers/constants';


const EventLogsComponent = (props) => {
    const { t: translate } = useTranslation();
    const [timer, setTimer] = useState(null);
    const selectedCategoryRef = useRef(null);

    const [closeEventPopupVisible, setCloseEventPopupVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [expandedEventCategory, setExpandedEventCategory] = useState(null);
    const [selectedEvents, setSelectedEvents] = useState(null);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [isEventFiltered, setIsEventFiltered] = useState(false);

    const showCloseEventPopup = (event) => {
        setCloseEventPopupVisible(true);
        setSelectedEvent(event);
    };

    const {
        eventLogs,
        filters,
        selectedAssets,
        eventLogLoadingStatus,
        eventClosingLoadingStatus
    } = props.model;

    const {
        getEventLogs,
        closeEventLog,
    } = props.actions;

    const {
        isSS,
        assistanceCapability,
        setSelectedEventPage,
        setSelectedEventItemPage,
        selectedEventCategory,
        selectedEventItemPage
    } = props;

    const [eventLogData, setEventLogData] = useState(formatEventLogs(
        eventLogs,
        selectedAssets,
        isSS,
        filters.groupByNames,
        translate
    ));
    const [columnConfig, setColumnConfig] = useState(getColumnConfig(
        isSS,
        filters.groupByNames,
        translate,
        showCloseEventPopup,
        props.setSelectedEvent,
        assistanceCapability,
        filters.dateKind
    ));
    const [selectedPage, setSelectedPage] = useState(1);
    const shouldRequestEventLogs = useNewCapability().hasEventListCapability();

    const getPage = (list, page, itemsPerPage) => {
        const startIndex = itemsPerPage * (page - 1);
        const endIndex = startIndex + itemsPerPage;
        return slice(list, startIndex, endIndex < list.length ? endIndex : list.length);
    };

    useEffect(() => {
        setSelectedEvents(selectedEventCategory?.messageText);
        setSelectedEventId(selectedEventCategory?.eventId);
    }, [selectedEventCategory]);

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            selectedAssets?.length &&
                filters.dateSelection.from &&
                filters.dateSelection.to &&
                shouldRequestEventLogs &&
                getEventLogs(selectedAssets,
                    filters,
                    translate('ABB.Powertrain.Frontend.eventListTruncatedWarningMessage'));

        }, filters?.eventName?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [
        filters.dateSelection,
        filters.dateKind,
        filters.eventStatus,
        filters.eventTypes,
        filters.eventName,
        filters.causeOfEvent,
        filters.withCommentsOnly,
        filters.withDataLoggersOnly,
        JSON.stringify(selectedAssets)
    ]);

    useEffect(() => {
        const formattedEventLogData = formatEventLogs(
            eventLogs,
            selectedAssets,
            isSS,
            filters.groupByNames,
            translate
        );
        setEventLogData(formattedEventLogData);

        const calculatedPageNumber = Math.ceil(formattedEventLogData?.length / ITEMS_PER_PAGE);
        if (calculatedPageNumber < selectedPage) {
            if (selectedPage !== props.selectedEventPage) {
                setSelectedPage(props.selectedEventPage);
            } else {
                setSelectedPage(1);
            }
        }

        if (formattedEventLogData.length > 0) {
            if (expandedEventCategory && !formattedEventLogData.some(eventLog => eventLog.eventGroupName === expandedEventCategory)) {
                setExpandedEventCategory(null);
            }
        } else {
            setExpandedEventCategory(null);
        }

    }, [eventLogs, filters, expandedEventCategory]);

    useEffect(() => {
        setColumnConfig(getColumnConfig(
            isSS,
            filters.groupByNames,
            translate,
            showCloseEventPopup,
            props.setSelectedEvent,
            assistanceCapability,
            filters.dateKind
        ));
    }, [isSS, filters.groupByNames, filters.dateKind]);

    useEffect(() => {
        if (eventClosingLoadingStatus === apiCallStatus.SUCCESS && selectedAssets?.length) {
            const formattedEventLogData = formatEventLogs(
                getEventLogs(selectedAssets,
                    filters,
                    translate('ABB.Powertrain.Frontend.eventListTruncatedWarningMessage')),
                selectedAssets,
                isSS,
                filters.groupByNames,
                translate
            );
            setEventLogData(formattedEventLogData);
        }
    }, [eventClosingLoadingStatus]);

    const closeEventHandler = (closingReason, comment) => {
        closeEventLog(selectedEvent, closingReason, comment);
        if (selectedEvent?.eventGroupName) {
            setExpandedEventCategory(selectedEvent?.eventGroupName);
        }
    };

    const prevEventLogsRef = useRef(eventLogs);

    useEffect(() => {
        if (eventLogLoadingStatus === 'success' && JSON.stringify(prevEventLogsRef.current) !== JSON.stringify(eventLogs) && eventLogs?.length > 0) {
            setSelectedEvents(null);
            setSelectedPage(1);
            setSelectedEventPage(1);
            setIsEventFiltered(true);
            prevEventLogsRef.current = eventLogs;
        } else {
            setIsEventFiltered(false);
        }
    }, [eventLogs, props.setSelectedEvent]);

    const handlePageChange = (page) => {
        setSelectedPage(page);
        setSelectedEventPage(page);
        setSelectedEvents(null);
    };

    const renderEventGroups = () => {
        const content = eventLogData?.length ?
            map(getPage(eventLogData, selectedPage, ITEMS_PER_PAGE), (eventLogs) => {
                return (
                    <EventCategory
                        category={eventLogs.eventType}
                        isExpanded={expandedEventCategory === eventLogs?.eventGroupName || eventLogs?.id?.includes(selectedEvents)}
                        setIsExpanded={(expanded) => {
                            if (expanded) {
                                setExpandedEventCategory(eventLogs.eventGroupName);
                            } else {
                                setExpandedEventCategory(null);
                            }
                        }}
                        key={eventLogs.eventGroupName}
                        name={eventLogs.eventGroupName}
                        ref={eventLogs?.id?.includes(selectedEvents) ? selectedCategoryRef : null}
                    >
                        <GeneralTable
                            className='grouped-events'
                            tableKey='id'
                            scroll={{ x: 1200, y: null }}
                            columns={columnConfig}
                            data={eventLogs.events}
                            paginationProps={{ pageSize: ITEMS_PER_PAGE, hideOnSinglePage: true }}
                            initialSortingProps={{
                                column: isSS ? 'occurredOn' : 'time',
                                direction: sortingDirections.DESC
                            }}
                            requiredPaginateHandler={true}
                            setSelectedEventItemPage={setSelectedEventItemPage}
                            selectedEventItemPage={eventLogs?.id?.includes(selectedEvents) ? selectedEventItemPage : null}
                            selectedEventId={selectedEventId}
                            isEventFiltered={isEventFiltered}
                        />
                    </EventCategory>
                );
            })
            : <div className='event-log-no-information'>
                {translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
            </div>;
        return (
            <>
                <div className='detailed-info-event-groups'>{content}</div>
                <GeneralPagination
                    currentPage={selectedPage}
                    total={eventLogData.length}
                    pageSize={ITEMS_PER_PAGE}
                    onChange={handlePageChange}
                    hideOnSinglePage={true}
                />
            </>
        );
    };

    return (
        <div className='detailed-info-event-log-container'>
            <LoadingStatusWrapper loadingStatus={[eventLogLoadingStatus, eventClosingLoadingStatus]}>
                <CloseEventDialogComponent
                    show={closeEventPopupVisible}
                    close={() => { setCloseEventPopupVisible(false); }}
                    closeEventHandler={closeEventHandler}
                />

                {filters.groupByNames || isSS ?
                    renderEventGroups()
                    : <GeneralTable
                        className='ungrouped-events'
                        scroll={{ x: 1200, y: null }}
                        tableKey='id'
                        columns={columnConfig}
                        data={eventLogData.events || []}
                        emptyText={translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
                        paginationProps={{ pageSize: ITEMS_PER_PAGE, hideOnSinglePage: true }}
                        initialSortingProps={{
                            column: 'time',
                            direction: sortingDirections.DESC
                        }}
                        requiredPaginateHandler={true}
                        setSelectedEventItemPage={setSelectedEventItemPage}
                        selectedEventItemPage={selectedEventItemPage}
                        selectedEventId={selectedEventId}
                        isEventFiltered={isEventFiltered}
                    />
                }
            </LoadingStatusWrapper>
        </div>
    );
};

EventLogsComponent.propTypes = {
    model: PropTypes.shape({
        eventLogs: PropTypes.array,
        filters: PropTypes.object,
        selectedAssets: PropTypes.array,
        organizationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        eventLogLoadingStatus: PropTypes.string,
        eventClosingLoadingStatus: PropTypes.string,
        user: PropTypes.object
    }),
    actions: PropTypes.shape({
        getEventLogs: PropTypes.func,
        closeEventLog: PropTypes.func
    }),
    setSelectedEvent: PropTypes.func,
    isSS: PropTypes.bool,
    assistanceCapability: PropTypes.bool,
    isExpanded: PropTypes.bool,
    setIsExapnded: PropTypes.func,
    selectedEventCategory: PropTypes.string,
    selectedEventPage: PropTypes.number,
    setSelectedEventPage: PropTypes.func
};

export default EventLogsComponent;
