import { useEffect, useState } from 'react';
import transform from 'lodash/transform';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';

import { translate } from 'helpers/translateHelper';

import { assetMenuItems } from './constants';


import { AlertAlarmOffset } from './AlertAlarmOffset';
import { ParameterBackup } from './ParameterBackup';
import { ParameterBackup as ParameterBackupV2 } from './ParameterBackupV2';
import { ChangedParameterList } from './ChangedParameterList';
import { ChangedParameterListV2 } from './ChangedParameterListV2';
import { RenameAsset } from './RenameAsset';
import { BulkUpload } from './BulkUpload';
import { useSelector } from 'react-redux';
import { MuteAsset } from './MuteAsset';
import { useUserCapability, useNewCapability } from 'sharedHooks';
import { generateInstantReport, navigateToReportingPage } from './helpers';
import { DownloadMeasurements } from './DownloadMeasurements';
import { DataLogger } from './DataLogger';
import { gatewayTypes } from 'helpers/constants';


const useHeaderMenus = (selectedAssetTypeId, asset, dataLoggerCommands) => {
    const capability = useNewCapability();
    const userCapability = useUserCapability();
    const hasAlert = capability.hasAlertAlarmCapabilityAvailable();
    const hasEdit = capability.hasEditComponentCapability();
    const hasParameter = capability.hasParameterBackupCapability();
    const hasChangedParameterListCapability = capability.hasChangedParameterListCapability();
    const hasChangedParameterListLegacyUserCapability = userCapability.hasChangedParameterListLegacyView();
    const hasChangedParameterListUserCapability = userCapability.hasChangedParameterListView();
    const hasParameterBackupLegacyUserCapability = userCapability.hasParameterBackupLegacyView();
    const hasParameterBackupUserCapabaility = userCapability.hasParameterBackupView();
    const hasBulkUploadCapability = capability.hasBulkUploadCapability();
    const hasInstantReportCapability = capability.hasInstantReportCapability();
    const hasMeasurement = capability.hasMeasurementExportCapability();
    const loggerCommandList = dataLoggerCommands?.data;
    const hasDataLoggerCommands = loggerCommandList?.dataLoggerCommands?.specialLoggerCommands?.commands?.length > 0 ||
        loggerCommandList?.dataLoggerCommands?.triggerLoggerCommands?.commands?.length > 0;

    const allHeaderMenus = [
        {
            id: assetMenuItems.UPDATE_ASSET,
            availableCapabilityFn: (capability) => capability.hasEditChkCapability(),
            title: translate('ABB.Powertrain.Frontend.componentRenameModalTitle'),
            modalContentComponent: RenameAsset,
            disabled: !hasEdit
        },
        {
            id: assetMenuItems.EXPORT_PARAMETERS,
            availableCapabilityFn: (capability) => capability.hasParameterBackupCapabilityAvailable() && hasParameterBackupLegacyUserCapability,
            title: translate('ABB.Powertrain.Frontend.parameterBackupTitle'),
            modalContentComponent: ParameterBackup,
            hidden: !(hasParameter && hasParameterBackupLegacyUserCapability)
        },
        {
            id: assetMenuItems.EXPORT_PARAMETERSV2,
            availableCapabilityFn: (capability) => capability.hasParameterBackupCapabilityAvailable() && hasParameterBackupUserCapabaility,
            title: translate('ABB.Powertrain.Frontend.parameterBackupTitleV2'),
            modalContentComponent: ParameterBackupV2,
            hidden: !(hasParameter && hasParameterBackupUserCapabaility)
        },
        {
            id: assetMenuItems.EXPORT_MEASUREMENTS,
            availableCapabilityFn: (capability) => capability.hasNoVisibility(),
            title: translate('ABB.Powertrain.Frontend.downloadMeasurementsLabel'),
            modalContentComponent: DownloadMeasurements,
            disabled: !hasMeasurement,
            hidden: true
        },
        {
            id: assetMenuItems.CHANGED_PARAMETER_LIST,
            availableCapabilityFn: (capability) => capability.hasChangedParameterListCapabilityAvailable() && hasChangedParameterListLegacyUserCapability,
            title: translate('ABB.Powertrain.Frontend.changedParametersList'),
            modalContentComponent: ChangedParameterList,
            hidden: !(hasChangedParameterListCapability && hasChangedParameterListLegacyUserCapability),
        },
        {
            id: assetMenuItems.CHANGED_PARAMETER_LIST_V2,
            availableCapabilityFn: (capability) => capability.hasChangedParameterListCapabilityAvailable() && hasChangedParameterListUserCapability,
            title: translate('ABB.Powertrain.Frontend.changedParametersListV2'),
            modalContentComponent: ChangedParameterListV2,
            hidden: !(hasChangedParameterListCapability && hasChangedParameterListUserCapability)
        },
        {
            id: assetMenuItems.BULK_UPLOAD,
            availableCapabilityFn: (capability) => capability.hasBulkUploadCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.ptHierarchyHeaderMenuBulkUploadLabel'),
            modalContentComponent: BulkUpload,
            disabled: !hasBulkUploadCapability
        },
        {
            id: assetMenuItems.GENERATE_INSTANT_REPORT,
            availableCapabilityFn: (capability) => capability.hasInstantReportCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.reportGenerateInstantReportLabel'),
            menuAction: generateInstantReport,
            disabled: !hasInstantReportCapability || !asset?.isMonitored
        },
        {
            id: assetMenuItems.VIEW_REPORTS_FOR_ASSET,
            title: translate('ABB.Powertrain.Frontend.reportViewReportsForAssetLabel'),
            menuAction: navigateToReportingPage,
            disabled: false
        },
        {
            id: assetMenuItems.ALERT_ALARM_OFFSET,
            availableCapabilityFn: (capability) => capability.hasAlertAlarmCapabilityAvailable(),
            title: translate('ABB.Powertrain.Frontend.alertAlarmConfigTitle'),
            modalContentComponent: AlertAlarmOffset,
            disabled: !hasAlert,
            hidden: true
        }, {
            id: assetMenuItems.MUTE_ASSET,
            title: translate('ABB.Powertrain.Frontend.muteAssetTitle'),
            availableCapabilityFn: () => userCapability.hasNotificationSettingsCapability(),
            modalContentComponent: MuteAsset
        }, {
            id: assetMenuItems.DATA_LOGGER_TRIGGER,
            title: translate('ABB.Powertrain.Frontend.dataLoggerTitle'),
            modalContentComponent: DataLogger,
            disabled: !((asset?.gatewayType === gatewayTypes.NETA21 || asset?.gatewayType === gatewayTypes.EGW02) && hasDataLoggerCommands)
        }
    ];
    const [headerMenus, setHeaderMenus] = useState([]);
    const [selectedHeaderMenu, setSelectedHeaderMenu] = useState();

    const selectedMenuItemId = useSelector(({ detailedInformation }) => detailedInformation.selectedMenuItem);

    useEffect(() => {
        setSelectedHeaderMenu(find(headerMenus, { id: selectedMenuItemId }));
    }, [selectedMenuItemId]);

    useEffect(() => {
        const menuItemsToRender = transform(allHeaderMenus, (acc, menu) => {
            if (!menu.availableCapabilityFn || menu.availableCapabilityFn(capability) && !menu.hidden) {
                acc.push({ ...menu });
            }
        }, []);
        setHeaderMenus(orderBy(menuItemsToRender, 'title'));
    }, [selectedAssetTypeId, capability]);

    return [headerMenus, selectedHeaderMenu];
};

export default useHeaderMenus;
