import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GeneralFilterModal } from './GeneralFilterModal';
import Tippy from '@tippy.js/react';
import { IconAssetGroup, IconDropDownMo, IconSiteList } from 'svgIcons/MotionPortalIcons';
import IconOrganization from 'svgIcons/MotionPortalIcons/IconOrganization';
import './style.scss';

const GeneralFilterNavBarComponent = ({ model }) => {
    const { isGeneralFilterVisibleOnRoute, selectedFilters } = model;
    const { organizations, sites, assetGroups } = selectedFilters;

    const { t: translate } = useTranslation();
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const selectedFiltersTooltipContent = () =>
        <div className='tooltip-content'>
            {sites?.length > 0 &&
                <div className='site-list'>
                    <label>{translate('ABB.Powertrain.Frontend.powertrainWizardSiteLabel')}:</label>
                    {sites.map((site, index) =>
                        <span key={index}>{index === 0 ? ' ' : ', '}{site.siteName}</span>
                    )}
                </div>
            }
            {assetGroups?.length > 0 &&
                <div className='asset-group-list'>
                    <label>{translate('ABB.Powertrain.Frontend.generalFiltersAssetGroupLabel')}:</label>
                    {assetGroups.map((assetGroup, index) =>
                        <span key={index}>{index === 0 ? ' ' : ', '}{assetGroup.name}</span>
                    )}
                </div>
            }
            {organizations?.length > 0 &&
                <div className='organization-list'>
                    {(assetGroups?.length || sites?.length > 0) && <label>{translate('ABB.Powertrain.Frontend.reportsOrganizationLabel')}:</label>}
                    {organizations.map((organization, index) =>
                        <span key={index}>{index === 0 ? ' ' : ', '}{organization.name}</span>
                    )}
                </div>
            }
        </div>;

    const GeneralFilterButtonLabel = () => {
        if (sites?.length) {
            return sites.length > 1 ? `${sites.length} ${translate('ABB.Powertrain.Frontend.generalFiltersSitesLabel')}` : sites[0].siteName;
        } else if (assetGroups?.length) {
            return assetGroups.length > 1 ? `${assetGroups.length} ${translate('ABB.Powertrain.Frontend.generalFiltersAssetGroupsLabel')}` : assetGroups[0].name;
        } else if (organizations?.length) {
            return organizations[0].name;
        }
        return '';
    };

    return isGeneralFilterVisibleOnRoute &&
        <div className='general-filter-container' role='general-filter-container'>
            {selectedFilters?.organizations?.length > 0 &&
                <Tippy
                    content={selectedFiltersTooltipContent()}
                    className='general-filter-tippy'
                    interactive={true}
                    interactiveBorder={10}
                    animation='scale'
                    theme='light-border'
                    trigger='mouseenter'
                    placement='bottom'
                    maxWidth='600px'
                >
                    <div
                        className='general-filter-menu'
                        onClick={() => setIsFilterModalOpen(true)}
                    >
                        {sites?.length ? <IconSiteList /> : assetGroups?.length ? <IconAssetGroup /> : <IconOrganization width={16} height={16} />}
                        <span>{GeneralFilterButtonLabel()}</span>
                        <IconDropDownMo width={10} height={10} />
                    </div>
                </Tippy>
            }
            <GeneralFilterModal
                show={isFilterModalOpen}
                onHide={() => setIsFilterModalOpen(false)}
            />
        </div>;
};

GeneralFilterNavBarComponent.propTypes = {
    model: PropTypes.shape({
        isGeneralFilterVisibleOnRoute: PropTypes.bool,
        selectedFilters: PropTypes.object
    })
};

export default GeneralFilterNavBarComponent;
